<template>
  <b-modal ref="modal" centered scrollable size="xl" :title="data.title">
    <b-container
      fluid
      class="ma-0 pa-0"
      style="width:85vw;height: 75vh;overflow:auto;"
    >
      <ItemSubmissionForm ref="itemForm" :id="data.id" action="preview" />
    </b-container>
    <template #modal-footer="">
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button
          variant="outline-dark"
          class="m-1"
          @click="openItemInNewWindow(data.id)"
        >
          <font-awesome-icon icon="share" /> Open in new window
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="close()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import ItemSubmissionForm from "./ItemSubmissionForm";
export default {
  components: {
    ItemSubmissionForm
  },
  data() {
    return {
      mode: this.$constants.FORM_MODE.EDIT,

      data: {
        id: "",
        title: ""
      }
    };
  },
  methods: {
    show(payload) {
      this.data = {
        id: payload.id,
        title: `Item #${payload.name}`
      };

      this.$refs.modal.show();
    },

    close() {
      this.$refs.modal.hide();
      this.$emit("close");
    },
    openItemInNewWindow(id) {
      /*
      let routeData = this.$router.resolve({
        name: "Inventory Item Submission",
        params: {
          id: id,
          action: "view"
        }

      });
      */
      //router.resolve returns empty href for this route because of second level
      window.open(`#/inventory/item/view/${id}`, "_blank");
    }
  }
};
</script>

<style scope>
::v-deep .modal-xl {
  max-width: 1600px !important;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1600px !important;
  }
}
</style>
