var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "xl",
      "title": _vm.data.title
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('hr'), _c('div', {
          staticClass: "form-row d-flex justify-content-end"
        }, [_c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.openItemInNewWindow(_vm.data.id);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "share"
          }
        }), _vm._v(" Open in new window ")], 1), _c('b-button', {
          staticClass: "m-1",
          attrs: {
            "variant": "outline-dark"
          },
          on: {
            "click": function click($event) {
              return _vm.close();
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "times"
          }
        }), _vm._v(" Close ")], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "width": "85vw",
      "height": "75vh",
      "overflow": "auto"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ItemSubmissionForm', {
    ref: "itemForm",
    attrs: {
      "id": _vm.data.id,
      "action": "preview"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }